//$prim: #D1141B
//$sec: white

$desc: 1024
$tab: 700
$mob: 560
$red: #f62044
body
	font-family: 'Roboto', sans-serif
	font-size: 14px
	line-height: 1.2
a
	text-decoration: none
img
	display: block
	max-width: 100%

=linkHover
	&:hover
		color: $red
=slab
	font-family: 'Roboto Slab', serif
*
	transition: all .25s
	&::selection
		background: $red
		color: white
.main-wrap
	background: url(../img/bg.jpg) center center no-repeat fixed
	+r($desc)
		padding-top: 80px
	+r($mob)
		background: #202040
.content
	padding: 45px 0 50px 0
	h1
		font-size: 40px
		color: white
		text-align: center
		margin-bottom: 25px
		font-weight: 400
		+slab
		+r($mob)
			font-size: 30px
	p,ul,ol
		color: white
		margin: 20px 0
		list-style: inside disc
		line-height: 20px
		a
			color: $red
			&:hover
				text-decoration: underline
	&__box
		background: rgba(white, .9)
		border-radius: 20px
		padding: 35px 20px 35px 20px
		+clr
		p,ul,ol
			color: #5f6278
		h1,h2,h3,h4,h5,h6
			color: #1a1e3a
			font-size: 28px
			font-weight: bold
			margin-bottom: 20px
			+slab()
			+r($mob)
				font-size: 25px
		&.has-margin
			margin-top: 20px
		&.no-padding-bottom
			padding-bottom: 15px

.bread
	position: absolute
	top: -25px
	left: 10px
	a,span
		display: inline-block
		font-size: 12px
		margin-right: 7px
	a
		color: #009ed0
	span
		color: white
.lang-select
	background: url("../img/lang.png")
	width: 50px
	height: 39px
	display: block
	+r($mob)
		display: none
.logo
	+r($desc)
		max-width: 100px
.inner
	width: 1024px
	margin: 0 auto
	position: relative
	padding: 0 10px
	min-width: 320px
	+r($desc)
		width: 100%

.nav
	display: flex
	justify-content: space-between
	flex-wrap: wrap
	li
		margin: 0 7px
	a
		color: white
		text-transform: uppercase
		font-size: 14px
		+linkHover
		+r($desc)
			&:hover
				color: white
				text-decoration: underline


.soc
	width: 40px
	height: 40px
	display: inline-block
	margin-right: 5px
	background-size: cover
	&:hover
		transform: rotate(-360deg)
	&_tw
		background: url("../img/tw.png")
	&_fb
		background: url("../img/fb.png")
		width: 43px
		height: 43px
.header
	padding: 25px 0 15px 0
	background: rgba(#202040, .8)
	+r($desc)
		position: fixed
		top: 0
		width: 100%
		left: 0
		padding: 10px 0
		z-index: 10
		background: #202040

	.inner
		display: flex
		justify-content: space-between
		align-items: flex-end
		padding-right: 20px
	&__socials
		position: absolute
		top: -20px
		right: 0
		+r($desc)
			display: none
	&__lang-select
		+r($desc)
			display: none
	&__nav
		+r($desc)
			position: absolute
			top: 60px
			background: #f62044
			display: block
			width: 30%
			padding: 20px 15px
			text-align: center
			min-width: 200px
			transition: .5s
			right: -101%
		&.is-active
			right: 0
		li
			+r($desc)
				margin-bottom: 20px
				&:last-of-type
					margin-bottom: 0


	&__burger
		display: none
		cursor: pointer
		padding: 5px

		width: 50px
		+r($desc)
			display: block
		span
			display: block
			height: 2px
			margin-bottom: 9px
			background: white



.advant
	margin: 25px 0
	background: rgba(#202040, .8)
	border-radius: 20px
	padding: 25px 20px
	+r($mob)
		margin: 0
	h2
		color: white
		text-align: center
		margin-bottom: 15px
		font-weight: 400
		+slab
		font-size: 24px
	&__box
		display: flex
		+r($mob)
			display: block
	&__item
		width: 33.333%
		text-align: center
		padding: 0 10px
		+r($mob)
			padding: 0
			width: 50%
			margin: 0 auto 15px auto
	&__circle
		width: 125px
		height: 125px
		border-radius: 50%
		margin: 0 auto
		background-color: #6d3051
		border: 7px solid #461c47
		position: relative
	&__ico
		display: block
		position: absolute
		top: 50%
		left: 50%
		transform: translate(-50%,-50%)
		&_trophy
			background: url("../img/trophy.png")
			width: 61px
			height: 65px
		&_chair
			background: url("../img/chair.png")
			width: 76px
			height: 62px
		&_slot
			background: url("../img/slot.png")
			width: 67px
			height: 70px
.rounded
	border-radius: 25px
.subscr
	form
		background: rgba(#f62044, .8)
		border-radius: 20px
		padding: 20px
		display: flex
		justify-content: space-between
		align-items: center
		+r($desc)
			display: block
	h3
		color: white
		font-size: 28px
		+slab
		font-weight: bold
		display: inline-block
		vertical-align: middle
		+r($desc)
			text-align: center
			display: block
		&:before
			display: inline-block
			content: ''
			vertical-align: middle
			margin-right: 10px
			background: url("../img/envelope.png") center center no-repeat
			width: 37px
			height: 28px
			position: relative
			top: -2px
	input, button
		height: 50px
		line-height: 50px
		text-align: center
		+r($desc)
			display: block
			width: 80%
			margin: 15px auto
	input
		background: white
		width: 290px
		padding: 0 20px
	button
		width: 240px
		background: #181834
		color: white
		+slab
		font-size: 18px
		font-weight: bold
		&:hover
			background: #202040


.footer
	background: rgba(#202040, .8)
	margin-top: 50px
	+r($mob)
		margin-top: 20px
	&__top
		padding: 20px
		border-bottom: 2px solid #272752
	&__bottom
		padding: 10px 0 20px 0
		justify-content: space-between
		align-items: flex-end
		display: flex
		flex-wrap: wrap
		+r($desc)
			justify-content: space-around
	&__partners
		display: flex
		justify-content: space-between
		align-items: center
		flex-wrap: wrap
		position: relative
		top: -7px
		+r($desc)
			margin-top: 20px
			width: 100%
			justify-content: space-around
		li
			margin: 0 10px
			+r($desc)
				margin: 10px
	&__nav
		+r($desc)
			justify-content: center
		li
			+r($desc)
				margin: 10px 20px

.burger
	width: 40px
	height: 40px
	position: absolute
	top: 40px
	left: 10px
	padding-top: 8px
	display: none
	cursor: pointer

	+r($tab)
		display: block
	span
		height: 4px
		margin-bottom: 7px
		display: block
		background: blue //

.copy
	background: #12122c
	padding: 15px 0
	p
		text-align: center
		color: #5f6278
.t
	border-radius: 20px
	background: rgba(white, .9)
	padding: 10px 20px 10px 20px
	&__row
		padding: 10px 0 10px 20px
		min-height: 70px
		display: flex
		justify-content: space-between
		align-items: center
		text-align: center
		border-bottom: 2px solid white
		+r($desc)
			padding: 0
		&:last-of-type
			border-bottom: 0
		strong
			font-size: 18px
			+slab
	&__1, &__2, &__3
		span
			color: #5f6278
		img
			margin: 0 auto
	&__1
		flex: 1
		mark
			background: transparent
			+slab
			font-size: 18px
			color: $red
			font-weight: bold
		a
			color: #009ed0
			font-weight: 700
			text-decoration: underline
			text-transform: uppercase
			&:hover
				color: $red
		i
			width: 46px
			height: 43px
			display: inline-block
			color: white
			background: url("../img/star-big.png") center center no-repeat
			background-size: cover
			padding: 14px 8px 0px 5px
	&__2
		flex: 2
	&__3
		flex: 3
		&.align-right
			text-align: right
			+r($mob)
				text-align: center
	&__desc-hidden
		+r($desc)
			display: none
	&__tab-hidden
		+r($tab)
			display: none
	&__btn
		+r($desc)
			padding: 10px 35px
			font-size: 16px
		+r($tab)
			padding: 10px 30px
		+r($mob)
			padding: 5px 10px
			font-size: 14px

	&__mob-hidden
		+r($mob)
			display: none


.btn
	background: $red
	color: white
	+slab
	text-align: center
	padding: 15px 57px
	font-size: 16px
	display: inline-block
	&_fullsize
		width: 100%
		font-size: 24px
		padding: 10px 57px
	&:hover
		background: #181834
.sort
	display: flex
	flex-wrap: wrap
	margin-bottom: 30px

	+r($mob)
		display: block
		text-align: center
		margin-bottom: 20px
	&__btn
		margin-right: 15px
		padding: 15px 23px
		color: white
		font-size: 18px
		font-weight: bold
		background: #afafc5
		display: inline-block
		+r($desc)
			padding: 12px 20px
		+r($tab)
			margin-bottom: 10px
			font-size: 16px

		&.is-active, &:hover
			background: #ffba00
.blog

	&__box
		display: flex
		justify-content: space-between
		flex-wrap: wrap
		margin-top: 30px
	&__item
		width: 32%
		max-height: 93px
		overflow: hidden
		+clr
		margin-bottom: 20px
		+r($desc)
			width: 47%
			max-height: none
		+r($mob)
			width: 100%
		&:hover
			transform: scale(1.03)
		img
			float: left
			margin-right: 10px
		strong
			+slab
			color: #1a1e3a
		span,time
			color: #5f6278
		span
			display: block
			margin: 5px 0

.pagi
	display: flex
	padding-top: 15px
	border-top: 2px solid white
	+r($mob)
		justify-content: center
	a
		+slab
		font-weight: bold
		padding: 10px 15px
		border-radius: 50%
		margin-right: 10px
		display: inline-block
		color: #1a1e3a
		&.is-active, &:hover
			background: white

.review
	display: flex
	padding-bottom: 10px
	+r($desc)
		display: block
	&__left
		max-width: 310px
		flex: 1
		+r($desc)
			margin: 0 auto 20px auto
	&__left-bottom
		background: white
		height: 45px
		line-height: 45px
		text-align: center
		strong
			display: inline-block
			vertical-align: middle
			font-size: 18px
			&:before
				display: inline-block
				position: relative
				top: -3px
				content: ' '
				vertical-align: middle
				background: url("../img/star.png") center center no-repeat
				width: 19px
				height: 18px
				margin-right: 7px
	&__btn
		margin-top: 20px

	&__main-img
		display: block
		max-width: 100%
		max-height: 170px
	&__right
		flex: 2
		padding-left: 20px
		>p
			margin: 0
		ul
			list-style: none
		li
			margin-top: 5px
			a
				text-decoration: underline
				font-weight: bold
				color: #009ed0
	&__right-box
		display: flex
		text-align: center
		+r($mob)
			display: block
	&__right-col
		width: 50%
		padding-right: 20px
		+r($mob)
			width: 100%

.sitemap
	h2,h3,h4,h5,h6
		font-size: 18px
		position: relative
		padding-left: 15px
		margin: 10px 0
		&:before
			position: relative
			content: '-'
			color: $red
			left: -10px
			top: 3px
			font-size: 30px
			font-weight: bold
	ul
		padding-left: 40px
		list-style: none
		margin: 10px 0
	li
		a
			color: #5f6278
			&:hover
				color: $red
.intro-img
	float: left
	margin-right: 20px
	margin-bottom: 20px
	+r($mob)
		float: none
		margin: 15px auto

.slots
	&__box
		margin-top: 30px
		display: flex
		justify-content: space-between
		flex-wrap: wrap
		+r($mob)
			margin-top: 20px
	&__item
		margin-bottom: 20px
		display: inline-block
		width: 23%
		+r($desc)
			margin: 15px 10px
			width: 29%
		+r($tab)
			width: 40%
		+r($mob)
			width: 100%
		&:hover
			transform: scale(1.03)
		strong
			display: block
			text-align: center
			color: #1a1e3a
			margin-top: 5px
		img
			display: block
			max-width: 100%
			margin: 0 auto